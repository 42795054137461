import { confirmPopup } from '../shared/actions/popups'
import { importFontAwesomeIcons } from './icons'

let jQuery = $
const Routes = window.Routes

$.extend(window, Routes) /* Extend js-routes globally */

importFontAwesomeIcons()

// TODO: see if [an updated version of] this could be accepted into the font-awesome-rails gem?
let FontAwesome = {
  /* js port of font-awesome-rails's fa_icon method
  NOTE: requires jquery!

  Creates an icon tag given an icon name and possible icon modifiers.

  Examples

     FontAwesome.icon("camera-retro")
     > $('<i class="icon-camera-retro"></i>')

     FontAwesome.icon("camera-retro", { text: "Take a photo" })
     > $('<i class="icon-camera-retro"></i> Take a photo')

     FontAwesome.icon("camera-retro 2x")
     > $('<i class="icon-camera-retro icon-2x"></i>')
     FontAwesome.icon(["camera-retro", "4x"])
     > $('<i class="icon-camera-retro icon-4x"></i>')
     FontAwesome.icon("spinner spin large")
     > $('<i class="icon-spinner icon-spin icon-large">')

     FontAwesome.icon("quote-left 4x muted", { class: "pull-left" })
     > $('<i class="icon-quote-left icon-4x icon-muted pull-left"></i>')

     FontAwesome.icon("user", { title: "User icon" })
     > $('<i class="icon-user" title="User icon"></i>')
  */
  icon: function (names, options) {
    if (names == null) {
      names = 'flag'
    }
    if (options == null) {
      options = {}
    }
    var container = $('<div/>')
    var icon = $('<i/>')

    var iconClasses = []
      .concat(names)
      .join(' ')
      .split(/\s+/)
    for (var i = 0; i < iconClasses.length; i++) {
      iconClasses[i] = 'fa-' + iconClasses[i]
    }

    icon.addClass('fa')
    icon.addClass(iconClasses.join(' '))
    icon.addClass(options['class'])
    delete options['class']

    if (options.text) {
      container.text(' ' + options.text)
    }
    delete options.text

    icon.attr(options)
    return container.prepend(icon).html()
  }
}
// We can cheat a little and create a window function since the name 'fa_icon' is unlikely to occur in other libs
const fa_icon = function (names, options) {
  return FontAwesome.icon(names, options)
}

$.fn.toggleFaIconClass = function (iconClass) {
  return this.toggleClass('fa-' + iconClass)
}

const getHeight = function () {
  return $(window).height()
}

const onMobile = function () {
  return window.transitioned_to_mobile
}

const onDesktop = function () {
  return !onMobile()
}

var isTestingTransactionCheck = false

var testTransactionCheck = function (scope) {
  var form = $(scope)
    .parents('form')
    .first()

  var indicator = $('.test-indicator')
  clearTransactionTest()
  indicator.show()

  form.ajaxSubmit({
    async: true,
    url: '/checks/transactions/test.xml',
    success: function (request) {
      var message = $(request)
        .find('run error')
        .text()
      var items = $('.transaction-check-step-item-fields div.item:visible')

      $(request)
        .find('run url result')
        .each(function () {
          var result = $(this).text()

          if (result === 'success') {
            if (
              $(request)
                .find('run url code')
                .text() === '200'
            ) {
              $('#check_success').show()
              $('#check_warning').hide()
            } else {
              $('#check_success').hide()
              $('#check_warning').show()
            }
            $('#check_fail').hide()
            $('#transaction_check_url_description').hide()
          } else if (result === 'failed') {
            $('#check_success').hide()
            $('#check_warning').hide()
            $('#check_fail').show()
            $('#transaction_check_url_description').html(message)
          }
        })

      var itemCount = 0
      $(request)
        .find('run steps step')
        .each(function () {
          var step = $(this)

          step.find('items item').each(function () {
            var result = $(this)
              .find('result')
              .text()

            var itemElem = items[itemCount]
            itemCount++
            if (itemElem) {
              if (result !== 'success' && result !== 'failed') {
                $(itemElem)
                  .find('.step-item-unknown')
                  .show()
              } else {
                $(itemElem)
                  .find('.step-item-unknown')
                  .hide()
              }
              if (result === 'success') {
                $(itemElem)
                  .find('.step-item-success')
                  .show()
              } else {
                $(itemElem)
                  .find('.step-item-success')
                  .hide()
              }

              if (result === 'failed') {
                $(itemElem)
                  .find('.step-item-failure')
                  .show()
                $(itemElem)
                  .find('.step-item-failure')
                  .popover('destroy')
                  .popover({ html: true, content: message, placement: 'right', trigger: 'manual' })
                  .popover('show')
                $(itemElem)
                  .find('.break')
                  .hide()
              } else {
                $(itemElem)
                  .find('.step-item-failure')
                  .hide()
                $(itemElem)
                  .find('.step-item-failure')
                  .popover('destroy')

                $(itemElem)
                  .find('.break')
                  .show()
              }
            }
          })
        })
      indicator.hide()
    }
  })
}

const csrfParam = function () {
  return $('meta[name=csrf-param]').attr('content')
}

const csrfToken = function () {
  return $('meta[name=csrf-token]').attr('content')
}

$ = jQuery.noConflict()

// Sweet Alerts Prompt to replace an Are You Sure? dialog with custom-defined text
const swalConfirm = function (title, text, buttonText, loadingText, confirmAction, cancelAction, options) {
  var defaults = {
    title: title,
    html: text,
    confirmButtonText: buttonText
  }

  var settings = $.extend({}, defaults, options)

  confirmPopup(settings).then(function (data) {
    var confirmed = data.value

    if (confirmed) {
      // If a preConfirm callback was provided, ensure it returns TRUE
      if (options && options.preConfirm && !options.preConfirm()) {
        return false
      }

      Swal.disableButtons()
      if (loadingText) {
        $('button.sweet-confirm').html(
          fa_icon('circle-o-notch spin', { class: 'space-md-right', text: loadingText + '...' })
        )
      }
      confirmAction()
    } else {
      if (cancelAction !== undefined) {
        cancelAction()
      }
    }
  })
}

$(function () {
  $(document).ajaxSend(function (event, jqXHR, ajaxOptions) {
    var type = ajaxOptions.type

    if (type != null && type !== 'GET' && type !== 'get') {
      var csrf = encodeURIComponent(csrfParam()) + '=' + encodeURIComponent(csrfToken())
      if (ajaxOptions.data) {
        ajaxOptions.data += '&' + csrf
      } else {
        ajaxOptions.data = csrf
      }
    }
  })

  if ($.fn.multiselect) {
    // We're not guaranteed to use bootstrap-multiselect on every page that uses application.js
    // Remove the default "search" icon from template
    $.fn.multiselect.Constructor.prototype.defaults.templates.filter = `
      <li class="multiselect-item filter">
        <div class="input-group" style="width:100%">
          <input class="form-control multiselect-search" type="text">
        </div>
      </li>`

    // Use FontAwesome icons when building multiselect dropdowns
    $.fn.multiselect.Constructor.prototype.defaults.templates.filterClearBtn = `
      <span class="input-group-btn">
        <button class="btn btn-default multiselect-clear-filter" type="button">
          <i class="fa fa-times"></i>
        </button>
      </span>`
  }

  // Set the default trigger to hover to avoid persisting tooltips on buttons
  $.fn.tooltip.Constructor.DEFAULTS.trigger = 'hover'

  // Initialize tooltips with default placement bottom
  $('[rel="tooltip"],[data-rel="tooltip"]').tooltip({ placement: 'bottom' })

  // Initialize popovers
  $('[rel="popover"],[data-rel="popover"]').popover()

  // this will alter the original selectpicker.defaults object to use our preferred defaults
  if ($.fn.selectpicker) {
    $.extend($.fn.selectpicker.defaults, { iconBase: 'fa', tickIcon: 'fa-check' })
  }

  $('div.nav_button').click(function () {
    var active = $(this).siblings('ul.submenu:first')
    active.slideToggle('fast')
    $('ul.submenu')
      .not(active)
      .slideUp('fast')
  })

  // Set overflow for popovers when accordion transition completes
  $('#check-detail').on('shown', function () {
    $('#check-detail').addClass('overflow-visible')
  })
  // Reset overflow when accordion gets hidden before transition starts
  $('#check-detail').on('hide', function () {
    $('#check-detail').removeClass('overflow-visible')
  })

  $(document).on('click', 'a.new-window', function () {
    window.open(this.href)
    return false
  })

  // Keep account jumper hidden until fully rendered
  $('select#account_jump_url').on('rendered.bs.select', function () {
    $('.account-jump-wrapper')
      .removeClass('hidden')
      .fadeIn()
  })

  // Set title on account jumper to not show <i>...</i>
  $('select#account_jump_url').on('rendered.bs.select', function () {
    var title = $('#account_jump_url')
      .find('option:selected')
      .data('title')
    $('button[data-id=account_jump_url]').attr('title', title)
  })

  // data for the graph drop-downs
  setSelectors()

  $(document).on('swal-open', '.sweet-alert', function (e) {
    unbindModalShortcuts() // Unbind modal triggering shortcuts
  })

  $(document).on('swal-closed', '.sweet-alert', function () {
    bindModalShortcuts()
  })

  $(document).on('show.bs.modal', '.modal', function () {
    var $largeModal = $('.rigor-modal-lg')
    var $regularModal = $('#myModal')

    if ($('.modal:visible').length > 0) {
      // Is a large and a regular modal at the same time?
      $largeModal.is(':visible') ? $largeModal.modal('hide') : $regularModal.modal('hide')
    }
  })

  // Sweet Alerts confirmations and alert prompts
  $(document).on('click', "[data-behavior='delete']", function (e) {
    var $this = $(this)
    var deletePath = $(this).attr('href')
    var objectType = $this.data('objectType') || 'item'

    e.preventDefault()

    var deleteSuccessRedirect = function () {
      swal({
        reverseButtons: true,
        title: 'Deleted.',
        html:
          '<p>The ' +
          objectType +
          ' has been deleted.<br/><br/>' +
          Rigor.spinner(null, { text: 'Redirecting...' }) +
          '</p>',
        type: 'success',
        timer: 3000,
        showConfirmButton: false,
        allowEscapeKey: false
      })
    }

    var deleteSuccessRefreshDashboard = function () {
      swal({
        reverseButtons: true,
        title: 'Deleted.',
        html:
          '<p>The check has been deleted.<br/><br/><span class="swal-reloading-message">' +
          Rigor.spinner(null, { text: 'Refreshing the dashboard...' }) +
          '</span></p>',
        type: 'success'
      })

      $('[ng-app]').on('checks_done_loading', function () {
        var reloadingMessage = $('.swal-reloading-message')
        reloadingMessage.html(fa_icon('check', { text: 'The dashboard has been updated.' }))
        reloadingMessage.addClass('swal-input-message').css('opacity', 1)
      })
    }

    var objectDeleteAjax = function () {
      var data = {
        format: 'json',
        _method: 'delete'
      }

      $.ajax({
        url: deletePath,
        type: 'DELETE',
        dataType: 'html',
        data: data,
        success: function () {
          var parentDataTable = $this.closest('table.dataTable')
          var redirectPath = $this.data('redirect-path')

          if (parentDataTable.length > 0 && !redirectPath) {
            // Deleting an item from a datatable
            swal('Deleted.', 'The ' + objectType + ' has been deleted.', 'success')
            var tableId = parentDataTable.attr('id')
            var $table = $('#' + tableId)
            var row = $this.closest('tr')
            let nRow = row[0]

            $table.dataTable().fnDeleteRow(nRow) // Delete the button's row within datatable
          } else if (
            $('[ng-app="ExecutiveDashboardApp"], [ng-app="AlertsDashboardApp"], [ng-app="OpsDashboardApp"]').length > 0
          ) {
            // Deleting a dashboard from show page
            if (objectType == 'dashboard') {
              var dashboardRedirectPath = $('[ng-app]').data('redirect-path')

              deleteSuccessRedirect()
              window.location = dashboardRedirectPath
            } else {
              // Click the reload app button when deleting a check from a dashboard
              let refreshApp = function () {
                var closestBoxHeader = $this.closest('.box-content').siblings('.box-header')
                var refreshButton = closestBoxHeader.find('.refresh-dashboard-button')

                refreshButton.click()
              }

              refreshApp()
              deleteSuccessRefreshDashboard()
            }
          } else {
            // Deleting an item from its show page
            deleteSuccessRedirect()
            window.location = redirectPath
          }
        },
        error: function () {
          swal({
            reverseButtons: true,
            title: 'Error',
            html:
              '<p>The ' +
              objectType +
              ' was not successfully deleted. <br/><br/>' +
              Rigor.spinner(null, { text: 'Redirecting...' }) +
              '</p>',
            type: 'error',
            allowEscapeKey: false,
            showConfirmButton: false
          })
          window.location.reload()
        }
      })
    }

    // Accounts get disabled, not deleted
    if (objectType === 'account') {
      swal(
        {
          reverseButtons: true,
          title: 'Do not delete accounts.',
          text: 'Please disable the account instead.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0e68a4',
          confirmButtonText: 'Let me disable it.',
          cancelButtonText: 'Cancel'
        },
        function (confirmed) {
          if (confirmed) {
            // redirect to account page
            window.location.href = deletePath
          }
        }
      )
    } else {
      var titleMsg = 'Delete this ' + objectType + '?'
      var textMsg = '<p>This action cannot be undone.</p>'
      var $confirmButton = $('button.sweet-confirm')

      var deleteButtonEnabled = function () {
        return $confirmButton.prop('disabled') === false
      }

      if ($.inArray(objectType, ['check', 'feature', 'test', 'location']) !== -1) {
        textMsg =
          '<p>This action cannot be undone.<br/>' +
          'To confirm, type <strong>DELETE</strong> in the box below.' +
          '</p>' +
          '<p><input id="swal-input-field" autocomplete="off">' +
          '<div class="swal-input-message">' +
          '<i class="fa fa-warning space-md-right red"></i>' +
          '<span class="swal-message-text"></span>' +
          '</div>' +
          '</p>'
      }

      $(document).on('keypress', '#swal-input-field', function (e) {
        e.stopImmediatePropagation()
        if (e.keyCode === 13 && deleteButtonEnabled()) {
          $confirmButton.click()
        }
      })

      swal(
        {
          reverseButtons: true,
          title: titleMsg,
          html: textMsg,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#FF6B55',
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          closeOnConfirm: false,
          closeOnCancel: true
        },
        function (confirmed) {
          if (confirmed) {
            var showLoadingIndicator = function () {
              swal.disableButtons()
              $confirmButton.html(fa_icon('circle-o-notch spin', { class: 'space-md-right', text: 'Deleting...' }))
            }
            // Force user to type DELETE when deleting a check
            if ($.inArray(objectType, ['check', 'feature', 'test', 'location']) !== -1) {
              var $inputField = $('input#swal-input-field')
              var inputValue = $inputField.val()
              var $inputMessage = $('.swal-input-message')
              var $inputMessageText = $('.swal-message-text')

              if (inputValue === 'DELETE') {
                showLoadingIndicator()
                objectDeleteAjax()
              } else {
                $inputMessageText.text('Invalid entry. Please try again.')
                $inputMessage.animate({ opacity: '1' }, 400)
              }
            } else {
              showLoadingIndicator()
              objectDeleteAjax()
            }
          }
        }
      )
    }
  }) // End of Sweet Alerts
})

// Load content to modal using Ajax
function clickLoadAjaxModal (selector, url, data) {
  var $modal = $('#ajaxModal')
  $(selector).on('click', function (e) {
    e.preventDefault()
    var $spinner = $('#ajaxLoadingSpinner')
    var $progressBar = $spinner.children('.progress')

    $spinner.removeClass('hidden')
    setTimeout(function () {
      $modal.load(url, data, function () {
        handleCustomModal('#ajaxModal')
        $(this).modal()
        $spinner.fadeTo(500, 0, function () {
          $(this).addClass('hidden')
          $(this).fadeTo(0, 1)
        })
      })
    }, 1000)
  })
}

export {
  fa_icon,
  getHeight,
  onMobile,
  onDesktop,
  isTestingTransactionCheck,
  testTransactionCheck,
  clickLoadAjaxModal,
  swalConfirm,
  csrfParam,
  csrfToken
}
