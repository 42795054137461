// aplication.js is an entrypoint for other JS files after jQuery has been loaded.
// application.js gets run from layouts/application.html.slim

import {
  fa_icon,
  getHeight,
  onMobile,
  onDesktop,
  isTestingTransactionCheck,
  testTransactionCheck,
  clickLoadAjaxModal,
  swalConfirm,
  csrfParam,
  csrfToken
} from '../application/main'
import '../application/polyfills'

// From main.js
window.fa_icon = fa_icon
window.getHeight = getHeight
window.onMobile = onMobile
window.onDesktop = onDesktop
window.isTestingTransactionCheck = isTestingTransactionCheck
window.testTransactionCheck = testTransactionCheck
window.clickLoadAjaxModal = clickLoadAjaxModal
window.swalConfirm = swalConfirm
window.csrfParam = csrfParam
window.csrfToken = csrfToken
