/* global unbindModalShortcuts, bindModalShortcuts */

export const popup = (options) => {
  return Swal.fire({
    onBeforeOpen () { unbindModalShortcuts() },
    onAfterClose () { bindModalShortcuts() },
    ...options
  })
}

export const successPopup = (options) => {
  return popup({
    type: 'success',
    title: 'Success',
    ...options
  })
}

export const errorPopup = (options) => {
  return popup({
    type: 'error',
    title: 'Error',
    ...options
  })
}

export const warningPopup = (options) => {
  return popup({
    type: 'warning',
    title: 'Warning',
    ...options
  })
}

export const confirmPopup = (options) => {
  return warningPopup({
    confirmButtonColor: '#0e68a4',
    showCancelButton: true,
    reverseButtons: true,
    ...options
  })
}
