String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

String.prototype.humanize = function () {
  return this.split('_').join(' ').capitalize()
}

String.prototype.titleize = function () {
  return _.map(this.split(' '), function (string) { return string.capitalize() }).join(' ')
}

// turn milliseconds into seconds
Number.prototype.humanizeTime = function (precision) {
  var precision = precision || 2

  if (this < 1000) {
    return (Math.round(this)).toString() + ' ms'
  } else {
    return (this * 0.001).toFixed(precision).toString() + ' s' // 2 decimal places
  }
}
